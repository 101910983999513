
import React, { useState } from 'react';

function App() {
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('male');
  const [activity, setActivity] = useState(1.55);
  const [calories, setCalories] = useState(null);

  const calculateCalories = () => {
    const genderConst = gender === 'male' ? 5 : -161;
    const bmr = 10 * weight + 6.25 * height - 5 * age + genderConst;
    const total = Math.round(bmr * activity);
    setCalories(total);
  };

  return (
    <div style={{ maxWidth: 600, margin: 'auto', padding: 20, fontFamily: 'sans-serif' }}>
      <h1>XPFitness</h1>
      <p>Level up your body – fast muscle growth for beginners.</p>
      <h2>Calorie Calculator</h2>
      <input placeholder="Weight (kg)" type="number" value={weight} onChange={e => setWeight(+e.target.value)} />
      <input placeholder="Height (cm)" type="number" value={height} onChange={e => setHeight(+e.target.value)} />
      <input placeholder="Age" type="number" value={age} onChange={e => setAge(+e.target.value)} />
      <select value={gender} onChange={e => setGender(e.target.value)}>
        <option value="male">Male</option>
        <option value="female">Female</option>
      </select>
      <select value={activity} onChange={e => setActivity(+e.target.value)}>
        <option value={1.2}>Sedentary</option>
        <option value={1.375}>Light</option>
        <option value={1.55}>Moderate</option>
        <option value={1.725}>Active</option>
        <option value={1.9}>Very Active</option>
      </select>
      <button onClick={calculateCalories}>Calculate</button>
      {calories && <p>Estimated Calories: {calories}</p>}
    </div>
  );
}

export default App;
